@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700&display=swap");

html {
  min-height: 100%;
}

body {
  font-family: "Fira Sans" !important;
  background-color: black !important;
  font-weight: 700;
  user-select: none;
  color: #ffed38;
  transition: background-image 0.3s ease-in-out;
  background-size: cover;
}

.container {
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc((var(--vh, 1vh) * 100));
  animation: fadein 1s;
}

.no-pad {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* Fallback for browsers that do not support Custom Properties */
}

.foreground {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flex-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.flex-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
  margin-top: -2em;
}

.brand-logo {
  align-self: flex-start;
  flex: 1;
}

.footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}


.countdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  // margin-top:-140px;
}

.countdown-header {
  text-align: center;
  font-size: 117px;
  text-shadow: 4px 11px #482583;
  color: #ffed38;
  margin-bottom: 20px;
}

.countdown-header-final {
  text-align: center;
  font-size: 64px;
  text-shadow: 4px 7px #482583;
  color: #ffed38;
  margin-bottom: 20px;
}

.countdown-header-final>img {
  width: 500px;
}

.countdown {
  display: flex;
  margin-top: -1em;
  flex-direction: column;
  align-items: center;
}

.countdown-value {
  display: flex;
}

.countdown-value-seconds {
  width: 200px;
}

.countdown-value-minutes {
  width: 200px;
}

.countdown-value-divider {
  font-size: 175px;
  text-shadow: 5px 12px #482583;
  color: #ffed38;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.countdown-marker {
  font-size: 40px;
  text-shadow: 2px 3px #482583;
  color: #ffed38;
  width: 100%;
  text-align: center;
}

.countdown-digit {
  font-size: 175px;
  text-shadow: 5px 12px #482583;
  color: #ffed38;
}

.footer-ig {
  font-size: 50px;
  display: flex;
  justify-content: center;
}

.footer-ig > a {
  display: flex;
  align-items: center;
}
.footer-ig {
  font-size:32px;
  margin-bottom:0.25em;
}

a {
  text-decoration: none;
  color: white;
  transition: all .2s ease-in-out;
}

a>svg {
  transition: all .2s ease-in-out;
}

a:visited {
  text-decoration: none;
  color: white;
  border: none;
}

@media (hover: hover) and (pointer: fine) {
  a:hover {
    text-decoration: none;
    color: white;
    border: none;
    transform: scale(1.1);
  }

  a:hover>svg {
    transform: scale(1.1);
  }
}

.brand-logo-svg {
  height: 100px;
  width: 100px;
  margin-left: 1em;
  overflow: hidden;
}

.footer-logo-svg {
  height: 200px;
  width: 350px;
  cursor: pointer;
  display: block;
  overflow: hidden;
}

@media only screen and (max-width: 550px) {

  .brand-logo > svg {
    height: 60px;
    margin-left:10px;
    margin-top:15px;
  }
  .countdown-header {
    font-size: 42px;
    text-shadow: 1px 3px #482583;
    margin-bottom: 20px;
  }

  .countdown-header-final {
    font-size: 40px;
    text-shadow: 2px 4px #482583;
    margin-bottom: 20px;
  }

  .countdown-header-final>img {
    width: 300px;
  }

  .countdown-value-seconds {
    width: 4.5em;
  }

  .countdown-value-minutes {
    width: 4.5em;
  }

  .countdown-value-divider {
    font-size: 67px;
    text-shadow: 2px 5px #482583;
  }

  .countdown-marker {
    font-size: 22px;
    text-shadow: 1px 2px #482583;
  }

  .countdown-digit {
    font-size: 67px;
    text-shadow: 2px 5px #482583;
  }

  .footer-logo-svg {
    height: 90px;
  }

  .footer-ig>a>img {
    height: 40px;
  }

  .footer-ig > a {
    display: flex;
    align-items: center;
  }
  .footer-ig {
    font-size:18px;
  }
}

@media only screen and (min-width: 550px) and (max-width: 1066px) {
  .countdown-header {
    font-size: 60px;
    text-shadow: 2px 5px #482583;
    margin-bottom: 20px;
  }

  .countdown-header-final {
    font-size: 50px;
    text-shadow: 3px 5px #482583;
    margin-bottom: 15px;
  }

  .countdown-header-final>img {
    width: 400px;
  }

  .countdown-value-seconds {
    width: 115px;
  }

  .countdown-value-minutes {
    width: 115px;
  }

  .countdown-value-divider {
    font-size: 100px;
    text-shadow: 3px 7px #482583;
  }

  .countdown-marker {
    font-size: 30px;
    text-shadow: 2px 4px #482583;
  }

  .countdown-digit {
    font-size: 100px;
    text-shadow: 3px 7px #482583;
  }

  .footer-logo-svg {
    transform: scale(0.9);
  }
}

@media only screen and (max-height:768px) {

  .container,
  .background,
  .foreground {
    height: 768px;
  }
}

@media only screen and (max-height:768px) and (max-width:550px) {

  .container,
  .background,
  .foreground {
    height: 100%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.react-cookie-law-accept-all-btn {
  background-color: #482583!important;
  color: #ffed38!important;

  margin: 16px 16px 0 0!important;
    padding: 0 26px!important;
    min-height: 44px!important;
    border: 2px solid #482583!important;
    border-radius: 3px!important;
    font-size: 14p!important;
    font-weight: 600!important;
    letter-spacing: .025em!important;
    line-height: 2.5!important;
    text-decoration: none!important;
}

.react-cookie-law-accept-selection-btn {
  background-color: #482583!important;
  color: #ffed38!important;
  border-radius: 3px!important;
  font-size: 14px!important;
  margin: 16px 16px 0 0!important;
  padding: 0 26px!important;
  min-height: 44px!important;
  border: 2px solid #482583!important;
  border-radius: 3px!important;
  font-size: 14px!important;
  font-weight: 600!important;
  letter-spacing: .025em!important;
  line-height: 2.5!important;
  text-decoration: none!important;
}

.react-cookie-law-option-checkbox {
  top:0px!important;
}

.react-cookie-law-option-wrapper > label {
  font-size:14px!important;
}

.react-cookie-law-dialog {
  top:unset!important;
  bottom:0px!important;
}